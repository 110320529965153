.pageContainer {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 16px;
}

.pageContainerThin {
  @media screen and (min-width: 1440px) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.pageContainerHeader {
  margin-top: 56px;
  margin-bottom: 40px;
}

.pageContainerLoader {
  display: flex;
  justify-content: center;
  padding: 100px 0px 100px 0px;
}

.pageDivider {
  display: block;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

@media screen and (max-width: 992px) {
  .pageDivider {
    display: none;
  }
}
