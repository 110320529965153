:root {
  --ds-font-family-headings: 'Poppins', sans-serif;
  --ds-font-family-body: 'Roboto', sans-serif;
  --ds-font-weight-bold: 700;
  --ds-font-weight-semibold: 600;
  --ds-font-weight-body: 400;

  --ds-font-size-body: 16px;
  --ds-font-size-body-lg: 20px;
  --ds-font-size-body-sm: 13px;
  --ds-line-height-body: 1.5;

  // display
  --ds-font-size-display: clamp(40px, calc((56 / 1440) * 100vw), 56px);
  --ds-line-height-display: calc(68 / 56);

  // h1
  --ds-font-size-h1: clamp(40px, calc((46 / 1440) * 100vw), 46px);
  --ds-line-height-h1: calc(54 / 46);

  // h2
  --ds-font-size-h2: clamp(32px, calc((38 / 1440) * 100vw), 38px);
  --ds-line-height-h2: calc(46 / 38);

  // h3
  --ds-font-size-h3: clamp(28px, calc((38 / 1440) * 100vw), 30px);
  --ds-line-height-h3: calc(38 / 30);

  // h4
  --ds-font-size-h4: 24px;
  --ds-line-height-h4: calc(34 / 24);

  // h5
  --ds-font-size-h5: 20px;
  --ds-line-height-h5: calc(24 / 20);

  // h6
  --ds-font-size-h6: 16px;
  --ds-line-height-h6: calc(24 / 16);
}
