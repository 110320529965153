.navigation {
  display: block;
  padding: 20px 0;
  background-color: var(--ds-color-white);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 10;
}

.navigation a {
  color: var(--ds-color-charcoal);
  text-decoration: none;
}

.navigationContainer,
.navigationContainer > ul:first-child {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navigationContainer > ul:first-child {
  padding: 0;
  width: 100%;
}

.navigationContainer ul {
  list-style: none;
  margin: 0;
}

.navigationMainMenu {
  background-color: var(--ds-color-white);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  display: none;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 100%;
  width: 100%;
  transition: height 0.2 ease-in-out;

  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    box-shadow: none;
    padding: 0;
    position: relative;
    top: 0;
  }
}

.navigationMainMenuShow {
  display: block;
}

.navigationMainMenu a {
  align-items: center;
  display: flex;
  padding: 12px 0;

  @media screen and (min-width: 992px) {
    padding: 8px 0;
  }
}

.navigation .navigationDropdownLinkActive {
  color: var(--ds-color-red-small);
}

.navigationDropdownLinkSelected,
.navigationDropdownLink:focus,
.navigation .navigationDropdownLink:active,
.navigationDropdown:focus-within .navigationDropdownLink {
  text-shadow: 0.5px 0 0 currentColor;
}

.navigationDropdownArrow {
  margin-left: 4px;
}

.navigationDropdown {
  position: relative;
}

.navigationDropdownContainer {
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;

  @media screen and (min-width: 992px) {
    background-color: var(--ds-color-white);
    box-shadow: 4px 8px 30px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    position: absolute;
    top: 100%;
    width: 200px;
  }
}

.navigationDropdownLinkSelected ~ .navigationDropdownContainer,
.navigationDropdownLink:focus ~ .navigationDropdownContainer,
.navigationDropdownContainer:focus-within {
  opacity: 1;
  height: auto;
  overflow: initial;
  z-index: 1;
}

.navigationDropdownContainer ul {
  padding-left: 20px;

  @media screen and (min-width: 992px) {
    padding-left: 0;
  }
}

.navigationDropdownContainer a {
  padding: 16px 0;

  &:hover,
  &:focus {
    background-color: var(--ds-color-lighter-grey);
  }

  @media screen and (min-width: 992px) {
    padding: 8px 16px;
  }
}

.navigationDropdownContainer li {
  &:first-child a:hover,
  &:first-child a:focus {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child a:hover,
  &:last-child a:focus {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.navigationMobileCta {
  appearance: none;
  background: transparent;
  border: 0;

  @media screen and (min-width: 992px) {
    display: none;
  }
}
