:root {
  /* colours are documented here: https://www.figma.com/file/VcwOm4p4iAah1Bb5e6Z1c0/Kilburn-Hub-Design-System?node-id=159%3A387 */
  /*  small is a concept in the design system where we should use the small variable when font sizes are less than 18pt/24px. */

  /* // Primary Colours */
  --ds-color-red: #ff233c;
  --ds-color-red-small: #e62139;
  --ds-color-charcoal: #323232;

  /* // Secondary Colours */
  --ds-color-purple: #a050ff;
  --ds-color-purple-small: #954bf2;
  --ds-color-blue: #2897ff;
  --ds-color-blue-small: #1d6cb6;
  --ds-color-navy: #044e94;
  --ds-color-pink: #e864bc;
  --ds-color-pink-small: #c93c9b;
  --ds-color-green: #43a813;
  --ds-color-green-small: #36870f;
  --ds-color-yellow: #ffc800;

  /* // Greys */
  --ds-color-black: #000000;
  --ds-color-dark-grey: #767676;
  --ds-color-mid-grey: #a3a3a3;
  --ds-color-light-grey: #dcdcdc;
  --ds-color-lighter-grey: #fafafa;
  --ds-color-white: #ffffff;

  --ds-color-grey: #868686;
  --ds-color-grey-small: #757575;

  /* // Not in the design system */
  --ds-color-box-shadow: #00000033;
}
