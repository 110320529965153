.footer {
  padding: 20px 0;
  background-color: var(--ds-color-charcoal);
  font-size: 18px;
  margin-top: 150px;

  @media screen and (min-width: 992px) {
    padding: 80px 0 160px 0;
  }
}

.footerInnerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
}

.footerLinks {
  @media screen and (min-width: 640px) {
    margin-left: 80px;
  }
}

.footerColumnContainer {
  display: flex;
  flex-wrap: wrap;

  .footerColumn {
    width: 50%;
  }

  @media screen and (min-width: 640px) {
    flex-wrap: nowrap;
    flex-direction: row;

    .footerColumn {
      width: auto;
      margin: 0 20px;
    }
  }
}

.footer .footerSubTitle {
  color: var(--ds-color-white);
  font-weight: var(--ds-font-weight-semibold);
  margin-bottom: 4px;
}

.footer .footerNote {
  color: var(--ds-color-white);
}

.footer .footerLinkList {
  list-style-type: none;
  padding-left: 0;
  color: var(--ds-color-light-grey);

  a {
    color: var(--ds-color-light-grey);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
