.text {
  font-size: var(--ds-font-size-body);
  font-weight: var(--ds-font-weight-body);
  line-height: var(--ds-line-height-body);
  color: var(--ds-color-charcoal);
  font-family: var(--ds-font-family-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
}

.textLARGE {
  font-size: var(--ds-font-size-body-lg);
}

.textSMALL {
  font-size: var(--ds-font-size-body-sm);
}

.textBOLD {
  font-weight: var(--ds-font-weight-bold);
}

.textCaps {
  text-transform: uppercase;
}
