.heading {
  color: var(--ds-color-charcoal);
  font-family: var(--ds-font-family-headings);
  font-weight: var(--ds-font-weight-bold);
  margin-bottom: 1rem;
}

.headingDISPLAY {
  font-size: var(--ds-font-size-display);
  line-height: var(--ds-line-height-h1);

  @media screen and (min-width: 1440px) {
    line-height: var(--ds-line-height-display);
  }
}

.headingH1 {
  font-size: var(--ds-font-size-h1);
  line-height: var(--ds-line-height-h1);
}

.headingH2 {
  font-size: var(--ds-font-size-h2);
  line-height: var(--ds-line-height-h2);
}

.headingH3 {
  font-size: var(--ds-font-size-h3);
  line-height: var(--ds-line-height-h3);
}

.headingH4 {
  font-size: var(--ds-font-size-h4);
  line-height: var(--ds-line-height-h4);
  font-weight: var(--ds-font-weight-semibold);
}

.headingH5 {
  font-size: var(--ds-font-size-h5);
  line-height: var(--ds-line-height-h5);
  font-weight: var(--ds-font-weight-semibold);
}

.headingH6 {
  font-size: var(--ds-font-size-h6);
  line-height: var(--ds-line-height-h6);
  font-weight: var(--ds-font-weight-semibold);
}
